import React from 'react';
import BuildBanner from '../../../components/banner_mobile/buildBanner';
import BlockJackpot from '../../../components/blockJackpot';
import AuthMobile from '../components/auth';
import NavigationMobile from '../components/navigation';
import SlotGame from '../components/slotgame';
import LiveGame from '../components/livegame';
import Language from '../components/language';
import { useRouter } from '../../../../../../@casino/hook/useRouter';
import SlotGameMobile from './slotgame';
import LiveGameMobile from './livegame';
import { useDispatch, useSelector } from 'react-redux';
import { setGamesListSlot } from '../store/thunk/setGamesSlot';
import { setGamesListLive } from '../store/thunk/setGamesLive';
import BonusesMobile from './bonuses';
import TournamentList from './tournament';
import { setGetListTournament } from '../../../../tournament/store/thunk/setGetListTournament';
import MobileTournamentView from './tournament/view';
import LotteryListMobile from './lottery';
import LoterryMobileView from './lottery/view';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../../../../dictionaries';
import Messanger from './messanger';
import { setGetFreespin } from '../../../../../template/FreespinModal/store/thunk/setGetFreespin';
import { setPostCreateFreespinV1 } from '../../../../../../store/freespin/thunk/setPostCreateFreespinV1';
import { ReactComponent as Present } from '../../../../../../@casino/img/present.svg';
import Modal from '../../../../../freespin/Modal';
import Logo from '../../../../../../@casino/img/logo.svg';
import TournamentMobile from '../../../../../../@casino/new_ui/tournamentMobile';

const useStyles = makeStyles(() => ({
    login: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
        display: 'flex',
        alignItems: 'center',
        color: '#D2D8E3',
        marginBottom: 6,
    },
    balance: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
    },
    active: {
        display: 'flex',
        justifyContent: 'center',
        color: '#fff',
        fontFamily: 'Montserrat,sans-serif',
        textTransform: 'uppercase',
        padding: 10,
        background: 'rgba(7, 44, 122, .4)',
        fontWeight: 700
    },
    notActive: {
        display: 'flex',
        justifyContent: 'center',
        color: '#eee',
        fontFamily: 'Montserrat,sans-serif',
        textTransform: 'uppercase',
        padding: 10,
        background: 'rgba(7, 44, 122, .1)'
    },
    present: {
        position: 'fixed',
        bottom: 30,
        right: 30,
        zIndex: 100,
        animation: '$presentEffect .9s infinite linear',
        '-webkit-animation': '$presentEffect .9s infinite linear',
        cursor: 'pointer'
    },
    "@keyframes presentEffect": {
        "0%": {
            bottom: 30,
            transform: 'scale(1)'
        },

        "50%": {
            bottom: 50,
            transform: 'scale(1.3)'
        },

        "100%": {
            bottom: 30,
            transform: 'scale(1)'
        }
    },
}))

const MobileHome = () => {
    const router = useRouter();
    const classes = useStyles();
    const dispatch = useDispatch();

    const { pathname } = router;

    React.useEffect(() => {
        dispatch(setGamesListLive({
            page: 1,
            type: 'live',
            provider: "",
            search: "",
            is_desktop: 0,
            is_mobile: 1
        }));
        dispatch(setGamesListSlot({
            page: 1,
            type: 'slots',
            provider: "",
            search: "",
            is_desktop: 0,
            is_mobile: 1
        }));
        dispatch(setGetListTournament({
            page: 1,
            rowsPerPage: 50
        }));
    }, [])

    const tournament = useSelector(state => state.tournament.list.tournaments);


    React.useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(setGetFreespin())
        }
    }, [])

    const profile = useSelector(state => state.profile.view);
    const fs = profile?.fs

    const currentDate = new Date().getTime();

    const [finishTime] = React.useState(Number(profile?.last_issue_fs) + 86400000);
    const [[diffDays, diffH, diffM, diffS], setDiff] = React.useState([0, 0, 0, 0]);
    const [tick, setTick] = React.useState(false);

    React.useEffect(() => {
        const diff = (finishTime - new Date()) / 1000;
        if (diff < 0) return
        setDiff([
            Math.floor(diff / 86400),
            Math.floor((diff / 3600) % 24),
            Math.floor((diff / 60) % 60),
            Math.floor(diff % 60)
        ])
    }, [tick, finishTime])

    React.useEffect(() => {
        if (profile?.active_bonuses_registration === 'fs50' && (Number(profile?.last_issue_fs) + 86400000) > currentDate && Number(profile?.count_issue_fs) < 3) {
            const timerID = setInterval(() => setTick(!tick), 1000);
            return () => clearInterval(timerID);
        }
    }, [tick]);

    const [isActiveBtn, setIsActiveBtn] = React.useState(true)
    const [isShowModal, setIsShowModal] = React.useState(true);

    return (
        <div style={{ position: 'relative', width: '100%', }}>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: 10, padding: 10, background: 'rgba(244, 244, 244,.2)' }} onClick={() => window.open('https://win777.partners', '_blank')}>
                <img src={Logo} alt="logo" style={{ width: 40 }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontFamily: 'Montserrat, sans-serif', fontSize: 12, color: '#FFF', fontWeight: 400 }}>{translate('common.487')}</span>
                </div>
                <div style={{ padding: '5px 10px', background: '#017AFE', borderRadius: '50px', display: 'flex', alignItems: 'ceenter' }}>
                    <span style={{
                        fontFamily: 'Montserrat, sans-serif',
                        color: '#FFFFFF',
                        fontSize: 14,
                    }}>{translate('common.488')}</span>
                </div>
            </div>
            <BuildBanner />
            {
                !isShowModal && <Modal />
            }
            {
                profile?.connection_groups === 'true' && fs?.status && Number(fs?.quantity > 0) && (!(Number(profile?.fs?.date_from) + 172800000 > new Date().getTime()) || !(Number(profile?.fs?.freespin_id) + 172800000 > new Date().getTime())) && (
                    <div
                        className={classes.present}
                        onClick={() => {
                            if (isActiveBtn) {
                                if (profile?.connection_groups === 'true') {
                                    dispatch(setPostCreateFreespinV1(setIsShowModal));
                                }
                                setIsActiveBtn(false);
                            }
                        }}
                    >
                        <div style={{ position: 'absolute', top: -10, left: -110, background: 'rgba(0,0,0,.4)', borderRadius: 5, padding: '5px 10px' }}>
                            {
                                profile?.connection_groups === 'true' && <span style={{ color: '#fff' }}>{translate('common.take_bonus')}</span>
                            }
                        </div>
                        <Present />
                    </div>
                )
            }
            <div>

                <Messanger />

            </div>
            <AuthMobile />
            <NavigationMobile />
            <BlockJackpot />
            {
                pathname === '/' &&
                <>
                    <SlotGame />
                    <LiveGame />
                    <div style={{ display: 'flex', paddingLeft: 20, paddingTop: 12, background: 'rgba(2, 0, 36, 0.8)' }}>
                        <span style={{ color: '#fff', fontSize: 16, fontFamily: 'Montserrat, sans-serif' }}>{translate('tournament.currentTournament')}</span>
                        <div style={{ borderRadius: '50%', background: 'red', width: 5, height: 5, marginLeft: 10 }}></div>
                    </div>
                    <div style={{ paddingBottom: 20, background: 'rgba(2, 0, 36, 0.8)', paddingTop: 8 }}>
                        {tournament.length && <TournamentMobile {...tournament[0]} />}
                    </div>


                </>
            }
            {
                pathname === '/mobile/slot' &&
                <>
                    <SlotGameMobile />
                </>
            }
            {
                pathname === '/mobile/live' &&
                <>
                    <LiveGameMobile />
                </>
            }
            {
                pathname === '/mobile/tournament' &&
                <>
                    <TournamentList />
                </>
            }
            {
                pathname.indexOf('/mobile/tournament/view') > -1 &&
                <>
                    <MobileTournamentView />
                </>
            }
            {
                pathname === '/mobile/lotteries' &&
                <>
                    <LotteryListMobile />
                </>
            }
            {
                pathname.indexOf('/mobile/lotteries/view') > -1 &&
                <>
                    <LoterryMobileView />
                </>
            }

            {
                pathname === '/mobile/bonuses' &&
                <>
                    <BonusesMobile />
                </>
            }
            <div style={{ paddingBottom: 20, background: 'rgba(2, 0, 36, 0.8)', paddingTop: 8 }}>
                <Language />
            </div>

        </div>
    )
}

export default MobileHome;