import React from 'react';
import { ReactComponent as ArrowBottom } from '../../../../../@casino/img/arrowBottom.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../../../dictionaries';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '../card';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 0
    },
    wrapLabelMethod: {

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start'
        }
    },
    labelMethod: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
    },
    wrapVisa: {
        width: 'calc(100% - 38px)',
        maxWidth: 374,
        padding: '15px 19px',
        border: '1px solid #8E9EC2',
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer'
    },
    visa: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#FFFFFF',
        lineHeight: '16px'
    },
    arrow: {
        position: 'absolute',
        top: 20,
        right: 20
    },
    wrapInput: {
        width: '100%',
        maxWidth: 414,
        border: '1px solid #8E9EC2',
        borderRadius: 3,
        position: 'relative'
    },
    inp: {
        width: '180px',
        padding: '14px 19px 14px 19px',
        outline: 'none',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        background: 'inherit',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF'
    },
    wrapBtn: {
        display: 'flex',
        marginTop: 40,
        marginLeft: 171,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            marginLeft: 0
        }
    },
    btn: {
        fontFamily: 'Montserrat, sans-serif',
        padding: '10px 40px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
        background: '#7B61FF',
        borderRadius: '50px',
        cursor: 'pointer',
    }
}))

const ReplenishmentBlock = ({ formik, currency, setSelected, payment }) => {
    const classes = useStyles();
    const [visible, setVisible] = React.useState(false);


    const getMethod = (cardType) => {
        switch (cardType) {
            case 1: return translate('common.payments.fkwallet');
            case 2: return translate('common.payments.fkwallet');
            case 4: return translate('common.payments.visa');
            case 6: return translate('common.payments.youmoney');
            case 8: return translate('common.payments.mastercard');
            case 13: return translate('common.payments.qiwi');
            case 14: return translate('common.payments.usdt_erc');
            case 15: return translate('common.payments.usdt_trc');
            case 26: return translate('common.payments.ethereum');
            case 27: return translate('common.payments.steampay');
            case 25: return translate('common.payments.litecoin');
            case 24: return translate('common.payments.bitcoin');
            case 33: return translate('common.payments.perfect_money');
            case 39: return translate('common.payments.trx');
            case 23: return translate('common.payments.ripple');
            case 19: return translate('common.payments.dogecoin');
            case 1001: return translate('common.payments.visa_mastercard_world');
            case 1002: return translate('common.payments.visa_mastercard_world');
            case 1100: return translate('common.payments.visa_mastercard_world');
            case 1300: return translate('common.payments.visa_mastercard_world');
            case 1400: return translate('common.payments.visa_mastercard_world');
            case 1500: return translate('common.payments.visa_mastercard_world');
            case 1600: return translate('common.payments.visa_mastercard_world');
            case 777: return translate('common.payments.visa_mastercard_world');
            case 1973: {
                if (currency === 'UZS') {
                    return "HUMO/UZCard"
                }
                return translate('common.payments.visa_mastercard_world');
            }
            case 1974: return "Система быстрых платежей";
            case 753: return "CRYPTO"
            case 1700: {
                if (currency === 'UZS') {
                    return "HUMO/UZCard"
                }
                return translate('common.payments.visa_mastercard_world');
            }
            case 9009: return "Piastrix"
            case 9050: {
                if (currency === 'UZS') {
                    return "HUMO/UZCard"
                }
                return translate('common.payments.visa_mastercard_world');
            }
            default: return 0
        }
    }
 
    return (
        <div className={classes.root}>
            <label htmlFor="method" className={classes.wrapLabelMethod}>
                <span className={classes.labelMethod}>{translate('common.method')}</span>
                <div className={classes.wrapVisa}>
                    <span className={classes.visa}>{getMethod(formik.values.cardType)}</span>
                    <ArrowBottom className={classes.arrow} />
                </div>
            </label>

            <label htmlFor="" className={classes.wrapLabelMethod} style={{ marginTop: 11, position: 'relative' }}>
                <span className={classes.labelMethod}>{translate('common.amount')}</span>
                <div className={classes.wrapInput}>
                    <input type="text"
                        onChange={(v) => {
                            if (!isNaN(+v.target.value)) {
                                formik.setFieldValue('amount', +v.target.value)
                            }
                        }}
                        value={formik.values.amount}
                        className={classes.inp}
                    />
                    <span style={{ color: '#fff', fontFamily: 'Montserrat, sans-serif', fontSize: 12, position: 'absolute', bottom: -20, left: 0 }}>
                        Лимит одной операции {new Intl.NumberFormat('ru-RU').format(formik.values.min)} - {new Intl.NumberFormat('ru-RU').format(formik.values.max)} {currency}
                    </span>
                </div>
            </label>
            <div className={classes.wrapBtn}>
                {
                    !visible && (
                        <span className={classes.btn} style={{ background: formik.values.amount < formik.values.min && 'inherit', border: +formik.values.amount < +formik.values.min && '1px solid #7B61FF' }}
                            onClick={() => {
                                if (+formik.values.amount >= +formik.values.min) {
                                    formik.submitForm();
                                    setVisible(true)
                                }
                            }}
                        >
                            {translate('common.replenishments')}
                        </span>
                    )
                }
                {
                    visible && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default ReplenishmentBlock