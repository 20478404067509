import React from 'react';
import WinPageBlock from '../../../@casino/ui/WinPageBlock';
import MethodReplenishment from './block/method';
import ReplenishmentBlock from './block/replen_block';
import {useFormik, FormikContext} from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { setReplenishmentCreate } from './store/thunk/setReplenishmentCreate';
import { setGetListPayment } from './store/thunk/setGetListPayment';
import {useRouter} from '../../../@casino/hook/useRouter';
import { translate } from '../../../dictionaries';
import { setGetTransaction } from '../../../store/card/thunk/setGetTransaction';
import { setGetCard } from '../../../store/card/thunk/setGetCard';
import { setPostReplenishemntCreateCrypo } from './store/thunk/setReplenishmentCreateCrypto';
import Card from './block/card';

const Replenishment = () => {

    document.title = translate('title.replenishment')
    const dispatch = useDispatch();

    const [selected, setSelected] = React.useState(false);
    const payment = useSelector(state => state.replenishment.list);

    React.useEffect(() => {
        dispatch(setGetTransaction())
    }, [])

    const initialValues = {
        amount: 0,
        method: 'visa-qiwi',
        cardType: '',
        min: 0,
        isCard: false,
        transaction: '',
        balanceSelected: 'slot'
    }

    React.useEffect(() => {
        dispatch(setGetCard())
        dispatch(setGetListPayment());
        dispatch(setGetTransaction());
    }, [])

    const onSubmit = (store) => {
        if (Number(store.cardType) === 753) {
            dispatch(setPostReplenishemntCreateCrypo(store, (value) => {
                // dispatch(setGetTransaction());
                // formik.setFieldValue('isCard', true);
                // formik.setFieldValue('transaction', value); 
            }))
        } else {
            dispatch(setReplenishmentCreate(store, (value) => {
                dispatch(setGetTransaction());
                formik.setFieldValue('isCard', true);
                formik.setFieldValue('transaction', value); 
            }))
        }
        
    }
   
    const formik = useFormik({onSubmit, initialValues});
    const groups = useSelector(state => state.profile.view);
    const transaction = useSelector((state) => state.card.transaction);

    React.useEffect(() => {
        if (transaction?.bill_id) {
            formik.setFieldValue('created', transaction.data);
            formik.setFieldValue('amount', transaction.amount);
            formik.setFieldValue('isCard', true);
            formik.setFieldValue('method', 'visa-qiwi');
            formik.setFieldValue('min', 300);
            formik.setFieldValue('transaction', transaction.bill_id);
            formik.setFieldValue('cardType', 777);
            formik.setFieldValue('balanceSelected', 'slot');
            setSelected(true)
        }
    }, [transaction]);

    const profile = useSelector(state => state.profile.view);

    React.useEffect(() => {
        if (!transaction?.bill_id && payment.length) {
            const payments = payment.filter((item) => item.status && item.currency === profile.currency)
            formik.setFieldValue('cardType', Number(payments?.[0]?.payment_id));
            formik.setFieldValue('min', profile?.balance < payments?.[0]?.limit_min ? payments?.[0]?.limit_min : profile?.balance);
            formik.setFieldValue('max', payments?.[0]?.limit_max);
        }     
    }, [payment])

    return (
        <WinPageBlock 
            title={translate('common.paymentReplenishment')}
        >
            <div style={{marginTop: 41}}>
                <FormikContext.Provider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        {
                            groups.groups_cashiers === 'all' && formik.values.isCard && <Card formik={formik} setSelected={setSelected} payment={payment} />
                        
                        }
                        {
                            groups.groups_cashiers === 'all' && !formik.values.isCard &&
                            <MethodReplenishment setSelected={setSelected} formik={formik} currency={groups.currency} payment={payment} user={groups} selected={selected} />
                        }   
                        {
                            (!payment.length ||  groups.groups_cashiers !== 'all') && 
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 300}}>
                                <span style={{
                                    color: '#D2D8E3', 
                                    fontFamily: 'Montserrat, sans-serif', 
                                    fontSize: 18, 
                                }}>{translate('common.notPayment')}</span>
                            </div>
                        }
                        {
                            groups.groups_cashiers === 'all' && !formik.values.isCard &&
                            <ReplenishmentBlock formik={formik} currency={groups.currency} setSelected={setSelected} payment={payment} />
                        }
                  </form>
                </FormikContext.Provider>
            </div>
        </WinPageBlock>
    )
}

export default Replenishment;