import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ReactComponent as Cross } from './cross.svg';
import { ReactComponent as Arrow } from './arrow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CheckboxDisabled } from './checkbox-disabled.svg';
import { ReactComponent as CheckboxActive } from './checkbox-active.svg';
import { useForm, useWatch } from 'react-hook-form';
import { setPostConfirmationStepRegistration } from '../../store/thunk/setPostConfirmationStepRegistration';
import { setPostRegistration } from '../../store/thunk/setPostRegistration';
import Confirmation from '../newConfirmation';
import { useRouter } from '../../../../../@casino/hook/useRouter';
import { translate } from '../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        background: 'rgba(0,0,0,.3)',
        zIndex: 991,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    wrap: {
        background: '#0034A5',
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
        position: 'relative'
    },
    wrapTitle: {
        textAlign: 'center',
        marginBottom: 40
    },
    title: {
        color: '#FFF',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 30,
        fontWeight: 600,
        textTransform: 'uppercase',
        margin: 0,
        padding: 0
    },
    wrapField: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2.5,
        position: 'relative'
    },
    textField: {
        color: '#FFF',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 16,
        fontWeight: 600
    },
    field: {
        display: 'flex',
        width: 324,
        padding: '16px 17px',
        alignItems: 'center',
        gap: 10,
        borderRadius: 3,
        border: '1px solid #FFF',
        background: 'none',
        outline: 'none',
        color: '#fff',
        "&::placeholder": {
            color: '#8E9EC2',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 16,
            fontWeight: 600,
        },
        '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    link: {
        color: '#FFF',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 10,
        fontWeight: 400,
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    wrapBtn: {
        display: 'flex',
        padding: '20px 80px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 50,
        background: '#FF005C',
        boxShadow: '0px 4px 10px 0px #D3193A',
        cursor: 'pointer',
        marginBottom: 20,
        border: 'none'
    },
    btn: {
        color: '#FFF',
        textShadow: '0px 3px 3px rgba(187, 0, 45, 0.70)',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 18,
        fontWeight: 600,
    },
    cross: {
        position: 'absolute',
        top: 15,
        right: 15,
        cursor: 'pointer'
    },
    wrapBlockFields: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 20,
        marginBottom: 10
    },
    wrapSelectBlock: {
        display: 'flex',
        columnGap: 20
    },
    wrapSelect: {
        height: 24,
        border: '1px solid #fff',
        position: 'relative',
        padding: 12,
        width: 97,
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
        cursor: 'pointer',
    },
    text: {
        color: '#8E9EC2',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 10,
        fontWeight: 400,
    },
    wrapCheckbox: {
        position: 'relative',
        display: 'flex',
        columnGap: 14,
        alignItems: 'flex-start'
    },
    arrow: {
        position: 'absolute',
        top: 18,
        right: 12
    },
    flag: {
        width: 35,
        height: 26,
        display: 'block'
    },
    options: {
        padding: 12,
        border: '1px solid #fff',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        width: 97,
        left: -1,
        top: 69,
        background: '#0034A5',
        zIndex: 10,
        // rowGap: 10,
        maxHeight: 250,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: 2,
        },

        '&::-webkit-scrollbar-track': {
            background: '#f7f7f7',
            border: '5px solid transparent',
            backgroundClip: 'content-box',
        },

        '&::-webkit-scrollbar-thumb': {
            background: '#e5e5e5',
            border: '1px solid #e5e5e5',
            borderRadius: '3px',
            height: 30,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: 'grey',
            borderColor: 'grey',
        },
    },
    codeText: {
        color: '#8E9EC2',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 16,
        fontWeight: 600,
    },
    wrapBlockOption: {
        display: 'flex',
        columnGap: 10,
        padding: '5px 0'
    }
}))

const NewModalRegistration = ({ setShowModal, setShowModalConfirm, showModalConfirm, setIsShowModalAuth }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const router = useRouter();

    const arrayCodePhone = useSelector(state => state.settings.code_country);
    const currencies = useSelector(state => state.settings.currency);
    const countries = useSelector(state => state.settings.country);

    const { register, handleSubmit, setValue, control, setError, formState: { errors } } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            email: "",
            password: "",
            currency: "RUB",
            code: "7",
            iso: "ru",
            phone: "",
            country: translate("common.342"),
            name: "",
            surname: "",
            promocode: "",
            is_rules: true
        },
    });

    React.useEffect(() => {
        const referral = localStorage.getItem('referral');

        if (referral && referral !== "undefined") {
            setValue('promocode', referral)
        }

    }, [localStorage.getItem('referral')])

    const country = useWatch({
        control,
        name: 'country'
    });

    const currency = useWatch({
        control,
        name: 'currency'
    });

    const iso = useWatch({
        control,
        name: 'iso'
    });

    const code = useWatch({
        control,
        name: 'code'
    });

    const is_rules = useWatch({
        control,
        name: 'is_rules'
    });

    const name = useWatch({
        control,
        name: 'name'
    })

    const surname = useWatch({
        control,
        name: 'surname'
    })

    const email = useWatch({
        control,
        name: 'email'
    })

    const password = useWatch({
        control,
        name: 'password'
    })

    const phone = useWatch({
        control,
        name: 'phone'
    })

    const filter_phone = arrayCodePhone?.filter((item) => Number(item.code) === Number(code))?.[0];

    const [isShowFieldPromocode, setIsShowFieldPromocode] = React.useState(false);
    const [isShowCode, setIsShowCode] = React.useState(false);
    const [isShowCurrency, setIsShowCurrency] = React.useState(false);
    const [isShowCountry, setIsShowCountry] = React.useState(false);
    const [initialData, setInitialData] = React.useState({});
    const [errorRegistration, setErrorRegistration] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleIsShowFieldPromocode = () => {
        setIsShowFieldPromocode(!isShowFieldPromocode);
    }

    const handleIsShowCode = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsShowCode(!isShowCode);
        setIsShowCurrency(false);
        setIsShowCountry(false);
    }

    const handleIsShowCurrency = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsShowCurrency(!isShowCurrency);
        setIsShowCode(false);
        setIsShowCountry(false);
    }

    const handleIsShowCountry = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsShowCode(false);
        setIsShowCurrency(false);
        setIsShowCountry(!isShowCountry);
    }

    const handleSetCountry = (value) => {
        setValue('country', value);
        setIsShowCountry(false);
    }

    const handleSetCurrency = (value) => {
        setValue('currency', value.toUpperCase());
        setIsShowCurrency(false);
    }

    const handleSetCodeCountry = ({ iso, code }) => {
        setValue('code', code);
        setValue('iso', iso);
        setIsShowCode(false);
    }

    const handleCheckErrors = () => {
        if (!name) {
            setError("name", {
                types: {
                    required: translate("common.348"),
                },
            });
        }
        if (!email) {
            setError("email", {
                types: {
                    required: translate("common.348"),
                },
            });
        }
        if (!password) {
            setError("password", {
                types: {
                    required: translate("common.348"),
                },
            });
        }
        if (password && password?.length < 6) {
            setError("password", {
                types: {
                    required: translate("common.350"),
                },
            });
        }
        if (!phone) {
            setError("phone", {
                types: {
                    required: translate("common.348"),
                },
            });
        }
        if (phone && phone?.length !== Number(filter_phone?.length)) {
            setError("phone", {
                types: {
                    required: translate("common.349"),
                },
            });
        }
        if (!surname) {
            setError("surname", {
                types: {
                    required: translate("common.348"),
                },
            });
        }
        if (!is_rules) {
            setError("is_rules", {
                types: {
                    required: translate("common.348"),
                },
            });
        }
    }

    const isFormValid = !name
        || !surname
        || !email
        || !password
        || (password && password?.length < 6)
        || !phone
        || (phone && phone?.length !== Number(filter_phone?.length))

    const onSubmit = (store) => {
        if (isFormValid) {
            handleCheckErrors()
        } else if (!isLoading) {
            const params = {
                login: store.phone,
                email: store.email,
                password: store.password,
                type: "phone",
                promocode: store.promocode,
                currency: store.currency,
                code: store.code,
                country: store.country,
                name: store.name,
                surname: store.surname
            }

            if (Number(store.code) === 7 && Number(store.phone?.[0]) !== 7) {
                setInitialData(params)
                dispatch(setPostConfirmationStepRegistration(params, setShowModalConfirm, setErrorRegistration, setIsLoading))
            } else {
                dispatch(setPostRegistration(params, setShowModalConfirm, setErrorRegistration, setIsLoading))
            }
        }
    }

    React.useEffect(() => {
        setErrorRegistration(false);
    }, [phone])
  
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
                <div className={classes.wrap}>
                    <Cross className={classes.cross} onClick={() => setShowModal(false)} />
                    <div className={classes.wrapTitle}>
                        <h1 className={classes.title}>{translate("common.351")}</h1>
                    </div>
                    <div className={classes.wrapBlockFields}>
                        <label htmlFor="email" className={classes.wrapField}>
                            <span className={classes.textField}>{translate("common.352")}</span>
                            <input
                                {...register('email')}
                                type="email"
                                id="email"
                                placeholder={translate("common.354")}
                                className={classes.field}
                                style={{
                                    borderColor: errors?.email?.types?.required ? '#FF005C' : '#FFFFFF'
                                }}
                            />
                            <span style={{
                                position: 'absolute',
                                bottom: -15,
                                fontSize: 12,
                                color: '#FF005C'
                            }}>{errors?.email?.types?.required}</span>
                        </label>
                        <label htmlFor="password" className={classes.wrapField}>
                            <span className={classes.textField}>{translate("common.353")}</span>
                            <input
                                {...register('password')}
                                type="password"
                                id="password"
                                placeholder={translate("common.345")}
                                className={classes.field}
                                style={{
                                    borderColor: errors?.password?.types?.required ? '#FF005C' : '#FFFFFF'
                                }}
                            />
                            <span style={{
                                position: 'absolute',
                                bottom: -15,
                                fontSize: 12,
                                color: '#FF005C'
                            }}>{errors?.password?.types?.required}</span>
                        </label>

                        <div className={classes.wrapSelectBlock}>
                            <div className={classes.wrapSelect} onClick={handleIsShowCode}>
                                <img
                                    loading="lazy"
                                    src={`https://flagcdn.com/w40/${iso}.png`}
                                    srcSet={`https://flagcdn.com/w40/${iso}.png 2x`}
                                    className={classes.flag}
                                />
                                <span className={classes.codeText} style={{ color: '#FFFFFF' }}>+{code}</span>
                                <Arrow className={classes.arrow} />
                                {
                                    isShowCode && (
                                        <div
                                            className={classes.options}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            {
                                                arrayCodePhone?.map((item) => (
                                                    <div
                                                        className={classes.wrapBlockOption}
                                                        onClick={() => {
                                                            handleSetCodeCountry({
                                                                iso: item.iso,
                                                                code: item.code,
                                                            })
                                                        }}
                                                    >
                                                        <img
                                                            loading="lazy"
                                                            src={`https://flagcdn.com/w40/${item.iso}.png`}
                                                            srcSet={`https://flagcdn.com/w40/${item.iso}.png 2x`}
                                                            className={classes.flag}
                                                        />
                                                        <span className={classes.codeText}>+{item.code}</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            <label htmlFor="phone" className={classes.wrapField}>
                                <input
                                    {...register('phone')}
                                    type="number"
                                    id="phone"
                                    placeholder={translate("common.369")}
                                    className={classes.field}
                                    style={{
                                        width: 181,
                                        borderColor: errors?.phone?.types?.required || errorRegistration ? '#FF005C' : '#FFFFFF'
                                    }}
                                />
                                <span style={{
                                    position: 'absolute',
                                    bottom: -15,
                                    fontSize: 12,
                                    color: '#FF005C'
                                }}>{errors?.phone?.types?.required}</span>
                                {
                                    errorRegistration && (
                                        <span style={{
                                            position: 'absolute',
                                            bottom: -15,
                                            fontSize: 12,
                                            color: '#FF005C'
                                        }}>{translate("common.372")}</span>
                                    )
                                }
                            </label>
                        </div>
                        <div className={classes.wrapSelectBlock}>
                            <div className={classes.wrapSelect} onClick={handleIsShowCurrency}>
                                <span style={{
                                    color: '#FFFFFF',
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontSize: 16,
                                    fontWeight: 600,
                                }}>{currency}</span>
                                <Arrow className={classes.arrow} />
                                {
                                    isShowCurrency && (
                                        <div
                                            className={classes.options}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            {
                                                currencies?.map((item) => (
                                                    <div
                                                        className={classes.wrapBlockOption}
                                                        onClick={() => {
                                                            handleSetCurrency(item.currency)
                                                        }}
                                                    >
                                                        <span style={{
                                                            color: '#8E9EC2',
                                                            fontFamily: 'Montserrat, sans-serif',
                                                            fontSize: 16,
                                                            fontWeight: 600,
                                                        }}>{item.currency}</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            <div className={classes.wrapSelect} style={{ width: 191 }} onClick={handleIsShowCountry}>
                                <span style={{
                                    color: '#FFFFFF',
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontSize: 16,
                                    fontWeight: 600,
                                }}>{country}</span>
                                <Arrow className={classes.arrow} />
                                {
                                    isShowCountry && (
                                        <div
                                            className={classes.options}
                                            style={{ width: 191, overflowX: 'hidden' }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                        >
                                            {
                                                countries?.map((item, key) => (
                                                    <div
                                                        className={classes.wrapBlockOption}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            handleSetCountry(item.country)
                                                        }}>
                                                        <span style={{
                                                            color: '#8E9EC2',
                                                            fontFamily: 'Montserrat, sans-serif',
                                                            fontSize: 16,
                                                            fontWeight: 600,
                                                        }}>{item.country}</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <label htmlFor="name" className={classes.wrapField}>
                            <input
                                {...register('name')}
                                type="text"
                                id="name"
                                placeholder={translate("common.355")}
                                className={classes.field}
                                style={{
                                    borderColor: errors?.name?.types?.required ? '#FF005C' : '#FFFFFF'
                                }}
                            />
                            <span style={{
                                position: 'absolute',
                                bottom: -15,
                                fontSize: 12,
                                color: '#FF005C'
                            }}>{errors?.name?.types?.required}</span>
                        </label>
                        <label htmlFor="surname" className={classes.wrapField}>
                            <input
                                {...register('surname')}
                                type="text"
                                id="surname"
                                placeholder={translate("common.356")}
                                className={classes.field}
                                style={{
                                    borderColor: errors?.surname?.types?.required ? '#FF005C' : '#FFFFFF'
                                }}
                            />
                            <span style={{
                                position: 'absolute',
                                bottom: -15,
                                fontSize: 12,
                                color: '#FF005C'
                            }}>{errors?.surname?.types?.required}</span>
                        </label>
                        {
                            isShowFieldPromocode && (
                                <label htmlFor="promocode" className={classes.wrapField}>
                                    <input
                                        {...register('promocode')}
                                        type="text"
                                        id="promocode"
                                        placeholder={translate("common.370")}
                                        className={classes.field}
                                    />
                                </label>
                            )
                        }
                    </div>
                    {
                        !isShowFieldPromocode && (
                            <div
                                onClick={handleIsShowFieldPromocode}
                                style={{
                                    cursor: 'pointer',
                                    marginBottom: 10
                                }}
                            >
                                <span className={classes.text} style={{ color: '#FFF', fontSize: 14 }}>+ {translate("common.357")}</span>
                            </div>
                        )
                    }
                    <div>
                        <label htmlFor="">
                            <div className={classes.wrapCheckbox}>
                                <div style={{ paddingTop: 3 }}>
                                    {
                                        is_rules && <CheckboxActive style={{ cursor: 'pointer' }} />
                                    }
                                    {
                                        !is_rules && <CheckboxDisabled style={{ cursor: 'pointer' }} />
                                    }
                                </div>
                                <div style={{ width: 330 }}>
                                    <span className={classes.text}>{translate("common.358")}</span>&nbsp;
                                    <span className={classes.link} onClick={() => {
                                        router.history.push('/regulations');
                                        setShowModal(false);
                                    }}>{translate("common.359")},</span>&nbsp;
                                    <span className={classes.link} onClick={() => {
                                        router.history.push('/responsible_game');
                                        setShowModal(false);
                                    }}>{translate("common.360")},</span>&nbsp;
                                    <span className={classes.link} onClick={() => {
                                        router.history.push('/cancellation_policy');
                                        setShowModal(false);
                                    }}>{translate("common.361")}</span>
                                </div>
                            </div>
                            <input type="checkbox" style={{ width: 0, height: 0 }} />

                        </label>
                    </div>

                    <button
                        type="submit"
                        className={classes.wrapBtn}
                    >
                        <span className={classes.btn}>{translate("common.362")}</span>
                    </button>


                    <div style={{ textAlign: 'center' }}>
                        <span className={classes.text} style={{ fontSize: 14 }}>{translate("common.363")}</span>&nbsp;
                        <span className={classes.link} style={{ fontSize: 14 }} onClick={() => {
                            setShowModal(false);
                            setIsShowModalAuth(true);
                        }}>{translate("common.364")}</span>
                    </div>
                </div>
            </form>
            <Confirmation showModalConfirm={showModalConfirm} setShowModalConfirm={setShowModalConfirm} initial={initialData}/> 
        </>
    )
}

export default NewModalRegistration;