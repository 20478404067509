import React from 'react';
import WinPageBlock from '../../../@casino/ui/WinPageBlock';
import { translate } from '../../../dictionaries';
import { SlotWithdrawals } from './SlotWithdrawals';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as BallTwo } from './ball3.svg';
import { ReactComponent as Slot2 } from './slot2.svg';
import { SportWithdrawals } from './SportWithdrawals';

const useStyles = makeStyles(() => ({
    balance: {
        padding: 20,
        border: '1px solid rgba(255,255,255,0.2)',
        borderRadius: 8,
        width: '40%',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'border .3s ease',
        display: 'flex',
        alignItems: 'center',
        columnGap: 8,
        '&:hover': {
            borderColor: 'rgb(255, 0, 92)',
            transition: 'border .3s ease'
        }
    }
}))

const Withdrawal = () => {
    document.title = translate('title.withdrawal');
    const classes = useStyles();

    const [balanceSelected, setBalanceSelected] = React.useState();

    return (
        <WinPageBlock
            title={translate('navigation.withdrawal')}
        >
            {
                !balanceSelected && (
                    <div style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '20px',
                        color: '#FFFFFF',
                        margin: 0,
                        padding: 0,
                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <span>{translate('common.455')}</span>
                        </div>
                        <div style={{ display: 'flex', marginTop: 20, columnGap: 12 }}>
                            <div
                                className={classes.balance}
                                onClick={() => {
                                    setBalanceSelected('slot');
                                }}
                            >
                                <span>
                                    <Slot2 style={{ width: 30, height: 30, fill: '#fff' }} />
                                </span>
                                <span>
                                    {translate('common.slot_balance')}
                                </span>
                            </div>
                            <div
                                className={classes.balance}
                                onClick={() => {
                                    setBalanceSelected('sport');
                                }}
                            >
                                <span>
                                    <BallTwo style={{ width: 30, height: 30, fill: '#fff' }} />
                                </span>
                                <span>
                                    {translate('common.sport_balance')}
                                </span>

                            </div>
                        </div>

                        <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', fontSize: 12 }}>
                            <span>{translate('common.456')}</span>
                        </div>
                    </div>
                )
            }
            {
                balanceSelected === 'slot' && (
                    <SlotWithdrawals />
                )
            }
            {
                balanceSelected === 'sport' && (
                    <SportWithdrawals />
                )
            }

        </WinPageBlock>
    )
}

export default Withdrawal;