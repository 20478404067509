import React from 'react';
import { getLabel, getLabelJackpot, getBackground, useStyles } from '../../helper';
import {useRouter} from '../../../../../../../@casino/hook/useRouter';
import { setGetBonusesNotificationAction } from '../../../../store/action';
import { useDispatch, useSelector } from 'react-redux';
import BackgroundBonuses from '../../../../../../../@casino/img/backgroundBonuses.png';
import {ReactComponent as Star} from '../../../../../../../@casino/img/star2.svg';
import Notification from '../../../notification';
import Confetti from 'react-confetti';
import { translate } from '../../../../../../../dictionaries';

const FormGame = ({profile}) => {
    const router = useRouter();
    const classes = useStyles();
    const dispatch = useDispatch();
    const jackpot = useSelector(state => state.games.list_jackpot);
    const notification = useSelector(state => state.games.notification);

    const getLogin = (val) => {
        return isNaN(+val) ? val :  '+' + val.slice(0, 1) + ' ' + val.slice(1, 4) + ' ' + val.slice(4, 7) + ' ' + val.slice(7, 9) + '-' + val.slice(9, 11);
    }

    const game = useSelector(state => state.games.game);

    return (
        <div style={{height: '100%'}}>
            {
                notification.bonuses && notification.bonuses.bonus_label && 
                <Notification 
                    labelHelper={translate('notification.helper.bonus')} 
                    label={getLabel(notification.bonuses.bonus_label)} 
                    amount={notification.bonuses.amount ? notification.bonuses.amount : notification.bonuses.amount.toFixed(2)}
                    success={() => dispatch(setGetBonusesNotificationAction({}))}
                />
            }
            {
                notification.jackpot && notification.jackpot.jackpot && 
                <div style={{width: 320, height: 500, position: 'fixed', top: '2%',right: '1%', zIndex: 900, display: 'flex', alignItems: 'center', transition: 'all 1s ease'}}>
                    <Confetti
                        width={320}
                        height={500}
                    />
                    <div style={{width: '100%', height: '100%', background: `url(${BackgroundBonuses})`, backgroundSize: 'cover', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: 10}}>
                        <span style={{
                            fontSize: 22,
                            color: '#fff',
                            fontWeight: 700,
                            fontFamily: 'Montserrat, sans-serif',
                            paddingLeft: 10,
                            marginBottom: 23,
                        }}>{translate('common.youWin')}</span>
                        <div style={{display: 'flex', alignItems: 'center', gap: 14, marginBottom: 23}}>
                            <Star style={{width: 60, height: 57}}/>
                            <span style={{
                                fontSize: 18,
                                color: '#fff',
                                fontWeight: 700,
                                fontFamily: 'Montserrat, sans-serif',
                            
                            }}>
                                {getLabelJackpot(notification.jackpot.jackpot)}
                            </span>
                        </div>
                        <div style={{marginBottom: 70}}>
                            <span style={{
                                fontSize: 25,
                                color: '#fff',
                                fontWeight: 700,
                                fontFamily: 'Montserrat, sans-serif'
                            }}>
                                {notification.jackpot.amount ? notification.jackpot.amount.toFixed(2) : notification.jackpot.amount}
                            </span>
                        </div>
                        <div style={{display: 'flex'}}>
                            <span style={{
                                padding: 15,
                                background: '#FF005C',
                                color: '#fff',
                                fontWeight: 700,
                                width: 200,
                                fontFamily: 'Montserrat, sans-serif',
                                borderRadius: '50px',
                                display: 'flex',
                                justifyContent: 'center',
                                cursor: 'pointer'
                            }}
                            onClick={() => dispatch(setGetBonusesNotificationAction({}))}
                            >{translate('common.ok')}</span>
                        </div>
                        </div>
                    </div>
                </div>
            }
            
            
            <div style={{height: '10%', background: "#041B4E", display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 20px'}}>
                <div style={{display: 'flex', alignItems: 'center', columnGap: 10, height: '100%'}}>
                    {
                        jackpot.map((item, key) => (
                            <div 
                                key={key}
                                className={classes.root} 
                                style={{background: `url(${getBackground(jackpot[key].jackpot)})`}}
                            >
                                <span className={classes.amountJackpot}>
                                    {new Intl.NumberFormat('ru-RU').format(jackpot[key].primary_amount.toFixed(2))}
                                </span>
                            </div>
                        ))
                    }
                </div>
                {
                    profile &&
                    <div style={{display: 'flex', columnGap: 10, alignItems: 'center', justifyContent: 'center'}}>
                        <span style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: '12px',
                            lineHeight: '15px',
                            color: '#FFFFFF'
                        }}>{profile?.login === '79653339666' ? '79********6' : profile.type_registrations !== 'phone' ? profile.login : getLogin(profile.login)}</span>
                        <span 
                            style={{
                                padding: '10px 15px', 
                                background: '#FF005C',
                                fontFamily: 'Montserrat, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                color: '#FFFFFF',
                                borderRadius: '50px',
                                cursor: 'pointer'
                            }}
                            onClick={() => router.history.push('/profile/replenishment')}
                        >{translate('common.replenishments')}</span>
                        <span style={{
                                padding: '10px 15px', 
                                background: '#FF005C',
                                fontFamily: 'Montserrat, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                color: '#FFFFFF',
                                borderRadius: '50px',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                if (game && game?.provider === 'Ezugi' || game?.provider == 'Betgames') {
                                    window.location.replace(window.location.origin + '/live')
                                } else {
                                    window.location.replace(window.location.origin)
                                }
                                
                            }}
                        >{translate('common.closeGame')}</span>
                    </div>
                }
                {
                    !profile &&
                    <>
                     <span style={{color: '#fff', fontSize: 30}}>{translate('common.demo')}</span>
                     <div>
                        <span style={{
                                padding: '20px 60px', 
                                background: '#FF005C',
                                fontFamily: 'Montserrat, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#FFFFFF',
                                borderRadius: '50px',
                                cursor: 'pointer'
                            }}
                            onClick={() => router.history.push('/auth')}
                            >{translate('auth.signin')}</span>
                        </div>
                    </>
                    
                }
            </div>
        </div>
    )
}

export default FormGame;