import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WinPageBlock from '../../../@casino/ui/WinPageBlock';
import { makeStyles } from '@material-ui/core';
import { translate } from '../../../dictionaries';
import { Button } from '@material-ui/core';
import { setPostCreateFreespin } from '../../../store/freespin/thunk/setPostCreateFreespin';
import { useFormik, FormikContext } from "formik";
import { setPostPinsale } from '../../../store/bonus/thunk/setPostPinsale';
import BonusesRegistration from './bonus_registration';
import BonusesFreespin from './freespin';
import { useRouter } from '../../../@casino/hook/useRouter';
import FreespinWin from './win_freespin';
import { setPostCreateFreespinPlayed } from '../../../store/freespin/thunk/setPostCreateFreespinPlayed';
import { setPostBonusConfirm } from '../../../store/freespin/thunk/setPostBonusConfirm';
import BonusUserWager from './BonusWager';
import { setGetWelcomeBonus } from '../../../store/welcomeBonus/thunk/setGetWelcomeBonus';
import BonusWagerMobile from './BonusWagerMobile';
import { BonusCard } from '../../BonusDeposit/components/BonusCard';
import { ReactComponent as FD1 } from '../../BonusDeposit/components/BonusCard/icon/fd_1.svg';
import { ReactComponent as FD2 } from '../../BonusDeposit/components/BonusCard/icon/fd_2.svg';
import { ReactComponent as RD1 } from '../../BonusDeposit/components/BonusCard/icon/rd_1.svg';
import { ReactComponent as RD2 } from '../../BonusDeposit/components/BonusCard/icon/rd_2.svg';
import { ReactComponent as TD1 } from '../../BonusDeposit/components/BonusCard/icon/td_1.svg';
import { ReactComponent as TD2 } from '../../BonusDeposit/components/BonusCard/icon/td_2.svg';
import { ReactComponent as FOD1 } from '../../BonusDeposit/components/BonusCard/icon/fo_1.svg';
import { ReactComponent as FOD2 } from '../../BonusDeposit/components/BonusCard/icon/fo_2.svg';
import { BonusCardMobile } from '../../BonusDeposit/components/BonusCardMobile';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 109,
    },
    wrapTitle: {
        marginBottom: 28
    },
    title: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '37px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF',
    },
    bonus: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#001137',
        borderRadius: '3px',
        width: 300,
        height: 120,
        marginRight: 20
    },
    activeBonus: {
        border: '1px solid #FF005C',
        boxSizing: 'border-box',
        boxShadow: '0px 3px 20px #FF005C',
    },
    textBonus: {
        width: '51%',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '29px',
        textAlign: 'center',
        paddingRight: 20
    },
    colorTextBonus: {
        color: '#FFFFFF',
    },
    colorTextActiveBonus: {
        color: '#FF005C',
    },
    iconBonus: {
        width: 117,
    },
    wrapBonusDesktop: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 31,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 20,
            alignItems: 'center',
            marginBottom: 40
        }
    },
    field2: {
        width: 300,
        border: '1px solid #8E9EC2',
        boxSizing: 'border-box',
        borderRadius: '3px',
        padding: '15px 19px',
        background: 'inherit',
        color: '#fff',
        outline: 'none',
        fontFamily: 'Montserrat, sans-serif',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        }
    },
    labelHelper: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        width: 171,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
}))

const Bonuses = () => {
    document.title = translate('title.bonuses')
    const dispatch = useDispatch();
    const classes = useStyles();
    const router = useRouter();

    const profile = useSelector(state => state.profile.view);
    const [loading, setLoading] = React.useState(false);

    const initialValues = {
        pinsale: ''
    }

    const onSubmit = (store) => {
        dispatch(setPostPinsale(store))
    }

    const formik = useFormik({ onSubmit, initialValues });

    React.useEffect(() => {
        dispatch(setGetWelcomeBonus());
    }, []);

    const welcomeBonus = useSelector((state) => state.welcomeBonus.view);

    return (
        <div>
            <WinPageBlock
                title={translate('navigation.bonuses')}
            >
                {
                    !profile?.dublicate && profile?.currency === 'RUB' && (
                        <FormikContext.Provider value={formik}>
                            <form onSubmit={formik.handleSubmit} className={classes.wrapBonusDesktop} style={{ display: 'flex', columnGap: 12, marginBottom: 50 }}>
                                <label htmlFor="pinsale">
                                    <input
                                        type="text"
                                        name="pinsale"
                                        id="pinsale"
                                        placeholder={translate('common.input_pinsale')}
                                        value={formik.values.pinsale}
                                        onChange={(v) => {
                                            if (!isNaN(+v.target.value)) {
                                                formik.setFieldValue('pinsale', v.target.value)
                                            }
                                        }}
                                        className={classes.field2}
                                        inputmode="decimal"
                                    />
                                </label>
                                <Button color="secondary" variant="contained" onClick={() => formik.submitForm()}>{translate('common.activate')}</Button>
                            </form>
                        </FormikContext.Provider>
                    )
                }
                {
                    profile?.connection_groups !== "true" && (Number(profile?.registration) + 864000000 > new Date().getTime()) && !profile?.dublicate && !Boolean(profile?.active_bonuses_registration) && <BonusesRegistration profile={profile} />
                }
                {
                    profile?.connection_groups !== "true" && (Number(profile?.fs?.freespin_id) + 172800000 > new Date().getTime()) && Boolean(profile?.fs?.freespin_id && profile?.fs?.quantity > 0) && <BonusesFreespin profile={profile} btn={translate('common.gaming')} onSubmit={() => router.history.push(`/games/${profile?.fs?.game_uuid}`)} date={profile?.fs?.freespin_id} max_date={172800000} loading={loading} />
                }
                {
                    profile?.connection_groups !== "true" && (Number(profile?.fs?.date_from) + 172800000 > new Date().getTime()) && Boolean(!profile?.fs?.freespin_id && profile?.fs?.status) && <BonusesFreespin profile={profile} btn={translate('common.activate')} onSubmit={() => dispatch(setPostCreateFreespin({ fs_id: profile?.fs?.id }, setLoading))} date={profile?.fs?.date_from} max_date={172800000} loading={loading} />
                }
                {
                    profile?.connection_groups !== "true" && (Number(profile?.fs?.freespin_id) + 172800000 > new Date().getTime()) && Boolean(profile?.fs?.freespin_id && Number(profile?.fs?.quantity) === 0 && profile?.fs?.status && profile?.fs?.win_amount) && <FreespinWin profile={profile} btn={translate('common.activate')} onSubmit={() => { setLoading(true); if (!loading) { dispatch(setPostCreateFreespinPlayed({ fs_id: profile?.fs?.id })) } }} date={profile?.fs?.freespin_id} max_date={172800000} wager={profile?.fs?.wager_amount} amount={profile?.fs?.win_amount} />
                }
                {
                    profile?.connection_groups !== "true" && (Number(profile?.fs_played?.date_from) + 172800000 > new Date().getTime()) && Boolean(profile?.fs_played?.status) && <FreespinWin profile={profile} onSubmit={() => {
                        setLoading(true)
                        if (!loading) {
                            dispatch(setPostBonusConfirm({ fs_played_id: profile?.fs_played?.id }))
                        }
                    }} date={profile?.fs_played?.date_from} max_date={172800000} wager={profile?.fs_played?.wager_count} wager_amount={profile?.fs_played?.wager} wager_played={profile?.fs_played?.wager_played > profile?.fs_played?.wager ? profile?.fs_played?.wager : profile?.fs_played?.wager_played} amount={profile?.fs_played?.amount} />
                }


                {
                    !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) && !profile?.dublicate && profile?.connection_groups !== "true" && welcomeBonus?.type !== 'bonus' && welcomeBonus?.status && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 20,
                            marginTop: 20
                        }}>
                            {
                                welcomeBonus?.status && (
                                    <BonusUserWager
                                        currency={profile?.currency}
                                        {...welcomeBonus}
                                        rule_one={welcomeBonus?.type !== 'bonus' ? profile?.count_deposit <= 1 ? translate('common.398') : profile?.count_deposit <= 2 ? translate('common.404') : profile?.count_deposit <= 3 ? translate('common.406') : translate('common.408') : ''}
                                        rule_two={profile?.count_deposit <= 1 ? translate('common.399') : profile?.count_deposit <= 2 ? translate('common.405') : profile?.count_deposit <= 3 ? translate('common.407') : translate('common.409')}
                                    />
                                )
                            }
                            {
                                profile?.count_deposit < 1 && (
                                    <BonusCard
                                        title={translate('common.436')}
                                        description={translate("common.437")}
                                        min_dep={translate("common.447")}
                                        max_bonus={translate('common.451')}
                                        IconOne={FD1}
                                        IconTwo={FD2}
                                    />
                                )
                            }
                            {
                                profile?.count_deposit < 2 && (
                                    <BonusCard
                                        title={translate("common.443")}
                                        description={translate("common.452")}
                                        min_dep={translate("common.447")}
                                        max_bonus={translate("common.450")}
                                        IconOne={RD1}
                                        IconTwo={RD2}
                                    />
                                )
                            }
                            {
                                profile?.count_deposit < 3 && (
                                    <BonusCard
                                        title={translate('common.444')}
                                        description={translate("common.453")}
                                        min_dep={translate("common.447")}
                                        max_bonus={translate("common.449")}
                                        IconOne={TD1}
                                        IconTwo={TD2}
                                    />
                                )
                            }
                            {
                                profile?.count_deposit < 4 && (
                                    <BonusCard
                                        title={translate("common.445")}
                                        description={translate("common.454")}
                                        min_dep={translate("common.447")}
                                        max_bonus={translate("common.448")}
                                        IconOne={FOD1}
                                        IconTwo={FOD2}
                                    />
                                )
                            }
                        </div>
                    )
                }
                {
                    !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) && profile?.connection_groups !== "true" && welcomeBonus?.type === 'bonus' && welcomeBonus?.status && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 20,
                            marginTop: 20
                        }}>
                            <BonusUserWager
                                currency={profile?.currency}
                                {...welcomeBonus}
                                rule_one={''}
                                rule_two={translate('common.409')}
                            />
                        </div>
                    )
                }
                {
                    (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) && !profile?.dublicate && profile?.connection_groups !== "true" && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 20,
                            marginTop: 20
                        }}>
                            {
                                welcomeBonus?.status && (
                                    <BonusWagerMobile
                                        currency={profile?.currency}
                                        {...welcomeBonus}
                                        rule_one={welcomeBonus?.type !== 'bonus' ? profile?.count_deposit <= 1 ? translate('common.398') : profile?.count_deposit <= 2 ? translate('common.404') : profile?.count_deposit <= 3 ? translate('common.406') : translate('common.408') : ''}
                                        rule_two={profile?.count_deposit <= 1 ? translate('common.399') : profile?.count_deposit <= 2 ? translate('common.405') : profile?.count_deposit <= 3 ? translate('common.407') : translate('common.409')}
                                    />
                                )
                            }

                            {
                                profile?.count_deposit < 1 && (
                                    <BonusCardMobile
                                        title={translate('common.436')}
                                        description={translate("common.437")}
                                        min_dep={translate("common.447")}
                                        max_bonus={translate('common.451')}
                                        IconOne={FD1}
                                        IconTwo={FD2}
                                        route={localStorage.getItem('token') ? "/profile/replenishment" : '/?registration=true'}
                                    />
                                )
                            }
                            {
                                profile?.count_deposit < 2 && (
                                    <BonusCardMobile
                                        title={translate("common.443")}
                                        description={translate("common.452")}
                                        min_dep={translate("common.447")}
                                        max_bonus={translate("common.450")}
                                        IconOne={RD1}
                                        IconTwo={RD2}
                                        route={localStorage.getItem('token') ? "/profile/replenishment" : '/?registration=true'}
                                    />
                                )
                            }
                            {
                                profile?.count_deposit < 3 && (
                                    <BonusCardMobile
                                        title={translate('common.444')}
                                        description={translate("common.453")}
                                        min_dep={translate("common.447")}
                                        max_bonus={translate("common.449")}
                                        IconOne={TD1}
                                        IconTwo={TD2}
                                        route={localStorage.getItem('token') ? "/profile/replenishment" : '/?registration=true'}
                                    />
                                )
                            }
                            {
                                profile?.count_deposit < 4 && (
                                    <BonusCardMobile
                                        title={translate("common.445")}
                                        description={translate("common.454")}
                                        min_dep={translate("common.447")}
                                        max_bonus={translate("common.448")}
                                        IconOne={FOD1}
                                        IconTwo={FOD2}
                                        route={localStorage.getItem('token') ? "/profile/replenishment" : '/?registration=true'}
                                    />
                                )
                            }
                        </div>
                    )
                }
                {
                    (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) && profile?.connection_groups !== "true" && welcomeBonus?.status && welcomeBonus?.type === 'bonus' && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 20,
                            marginTop: 20
                        }}>
                            {
                                welcomeBonus?.status && (
                                    <BonusWagerMobile
                                        currency={profile?.currency}
                                        {...welcomeBonus}
                                        rule_one={''}
                                        rule_two={translate('common.409')}
                                    />
                                )
                            }
                        </div>
                    )
                }
            </WinPageBlock>
        </div>
    )
}

export default Bonuses;