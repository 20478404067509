import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ReactComponent as Search } from '../../../../../@casino/img/search.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setGamesList } from '../../store/thunk/setGamesList';
import clsx from 'clsx';
import { useRouter } from '../../../../../@casino/hook/useRouter';
import { setGamesAction } from '../../store/action';
import { setLoadingAction } from '../../../../loader/store/action';
import Dialog from '@material-ui/core/Dialog';
import { translate } from '../../../../../dictionaries';
import Loader from '../../../../loader';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 29,
        [theme.breakpoints.down('sm')]: {
            margin: '29px 10px 0 10px'
        }
    },
    item_nav: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#8e9ec2',
        marginRight: 28,
        cursor: 'pointer',
        transition: 'color .2s ease',
        '&:hover': {
            color: '#FF005C',
            transition: 'color .2s ease',
        }
    },
    item_active: {
        color: '#FF005C'
    },
    btn: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        border: '1px solid #FF005C',
        borderRadius: '50px',
        padding: '10px 40px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        color: '#FF005C',
        '&:hover': {
            background: '#ED074C',
            color: '#fff',
            transition: 'background, color .3s ease',

        }
    },
    gamegame: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: 141,
        background: 'rgba(0,0,0,.5)',
        zIndex: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0,
        borderRadius: 20,
        transition: 'opacity .1s ease',
        '&:hover': {
            opacity: 1,
            transition: 'opacity .1s ease',
        }
    },
    search: {
        border: 'none',
        borderRadius: 3,
        paddingTop: 2,
        height: 40,
        width: 442,
        color: '#D2D8E3',
        fontSize: 16,
        fontFamily: 'Montserrat, sans-serif',
        background: '#142A8F',
        boxShadow: 'inset 0px 4px 4px #07215B',
        paddingLeft: 20,
        outline: 'none',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        }
    },
    wrapSearch: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    wrapGames: {
        paddingBottom: 60,
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 10
        }
    },
    wrapGameItem: {
        width: 210,
        height: 141,
        borderRadius: 3,
        position: 'relative',
        transition: 'all .3s ease',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minHeight: 141
        }
    },
    wrapImageGame: {
        width: 210,
        height: 141,
        borderRadius: 20,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minHeight: 141
        }
    }
}))

const ListGames = ({ provider, loading }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const router = useRouter();
    const type = router.pathname === '/live' ? 'live' : 'slots';
    
    const [search, setSearch] = React.useState('');
    const [active, setActive] = React.useState('all');
    const [showModal, setShowModal] = React.useState(false);

    const games = useSelector(state => state.games.list);
    const profile = useSelector(state => state.profile.view);

    React.useEffect(() => {
        dispatch(setGamesList({
            page: 1,
            type,
            provider,
            search,
            is_desktop: 1,
            is_mobile: 0,
        }));
    }, []);

    return (
        <div className={classes.root}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30 }}>
                <div>
                    <span
                        onClick={() => setActive('all')}
                        className={clsx(classes.item_nav, active === 'all' && classes.item_active)}>{translate('common.all')}</span>
                    <span
                        onClick={() => setActive('popular')}
                        className={clsx(classes.item_nav, active === 'popular' && classes.item_active)}>{translate('common.popular')}</span>
                </div>
                <div className={classes.wrapSearch}>
                    <input
                        type="text"
                        name=""
                        id=""
                        placeholder={translate('common.search')}
                        className={classes.search}
                        onChange={(v) => {
                            dispatch(setLoadingAction({ loading: true }))
                            dispatch(setGamesAction({
                                list: []
                            }))
                            dispatch(setGamesList({
                                page: 1,
                                type: 'slots',
                                provider,
                                search: v.target.value,
                                is_desktop: 1,
                                is_mobile: 0,
                            }));
                            setSearch(v.target.value)
                        }}
                    />
                    <div>
                        <Search style={{ position: 'absolute', top: 15, right: 13 }} />
                    </div>

                </div>
            </div>
            {
                loading &&
                <div>
                    <Loader />
                </div>
            }
            <div className={classes.wrapGames}>
                {
                    games?.list?.map((item) => (
                        <div
                            className={classes.wrapGameItem}
                            onClick={() => {
                                if ((item.provider === 'Betgames' || item.provider === 'Ezugi') && (profile && profile.air_balance)) {
                                    setShowModal(true)
                                } else {
                                    window.location.replace(window.location.origin + '/games/' + item.uuid)
                                }

                            }}
                        >
                            <div className={classes.gamegame}>
                                <span style={{ cursor: 'pointer', marginTop: 5, background: '#FF005C', padding: '10px 40px', borderRadius: '50px', color: '#fff', fontFamily: 'Montserrat', fontWeight: 500 }}>
                                    {translate('common.gaming')}
                                </span>
                            </div>

                            <img
                                src={item.image}
                                className={classes.wrapImageGame}
                                alt="" />
                        </div>
                    ))
                }
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 132 }}>
                <span
                    onClick={() => {
                        dispatch(setGamesList({
                            page: games.page + 1,
                            type,
                            provider,
                            search,
                            is_desktop: 1,
                            is_mobile: 0
                        }));
                    }}
                    className={classes.btn}>
                    {translate('common.show')}
                </span>
            </div>
            <Dialog
                open={showModal}
                onClose={() => setShowModal(false)}
            >
                <div className={classes.root}>
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '0 20px 20px', flexDirection: 'column', alignItems: 'center' }}>
                        <h3 style={{
                            margin: 0,
                            padding: 0,
                            fontFamily: 'Montserrat, sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '16px',

                            display: 'flex',
                            alignItems: 'center'
                        }}>{translate('notification.noaccessLive')}</h3>
                        <span style={{
                            marginTop: 12,
                            borderRadius: '50px',
                            padding: '5px 60px',
                            fontFamily: 'Montserrat, sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            background: '#FF005C',
                            color: '#FFFFFF',
                            cursor: 'pointer'
                        }}
                            onClick={() => setShowModal(false)}
                        >{translate('common.ok')}</span>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default ListGames;