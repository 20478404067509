import React from 'react';
import { translate } from '../../../../../../dictionaries';
import { ReactComponent as PiastrixIcon } from './piastrix.svg';
import { useSelector } from 'react-redux';

const PiastrixUZS = ({setSelected, formik}) => {
    const profile = useSelector(state => state.profile.view);

    return (
        <div
            onClick={() => {
                setSelected(true);
                formik.setFieldValue('cardType', 9009);
                formik.setFieldValue('min', profile?.balance < 40000 ? 40000 : profile?.balance);
            }}
            style={{
                overflow: 'hidden', 
                cursor: 'pointer', 
                width: 110, 
                height: 100, 
                background: '#fff', 
                borderRadius: 3, 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center'
            }}>
            <PiastrixIcon  style={{width: 'calc(100% - 10px)'}}/>
        </div>
    )
}

export default PiastrixUZS;