import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {setGetJackpotList} from '../../store/thunk/setGetJackpotList';
import { setGetJackpotHistory } from '../../store/thunk/setGetJackpotHistory';
import {getLabel, getBackground, columns, getRows, useStyles, columnsMobile, getRowsMobile} from './helper';
import WinTable from '../../../../../@casino/ui/WinTable';
import MainPageBlock from '../../../../../@casino/ui/mainPageBlock';
import { useRouter } from '../../../../../@casino/hook/useRouter';
import { translate } from '../../../../../dictionaries';

const JackpotList = () => {
    document.title = translate('title.jackpot');
    const classes = useStyles();
    const router = useRouter();
    const dispatch = useDispatch();
    const token = localStorage.getItem('token');
    const profile = useSelector(state => state.profile.view);

    React.useEffect(() => {
        if (profile && profile.groups_cashiers) {
            dispatch(setGetJackpotList({
                group: profile.groups_cashiers
            }))
            dispatch(setGetJackpotHistory({
                group: profile.groups_cashiers,
                page: 1,
                rowsPerPage: 10
            }))
        } else if (!!!token) {
            dispatch(setGetJackpotList({
                group: 'all'
            }))
            dispatch(setGetJackpotHistory({
                group: 'all',
                page: 1,
                rowsPerPage: 10
            }))
        }
    }, [profile, token])

    const jackpot = useSelector(state => state.jackpot.list);
    const history = useSelector(state => state.jackpot.history);

    const [count, setCount] = React.useState(0);

    const rows = React.useMemo(() => getRows(history.list, router), [history.list]);
    const rowsMobile = React.useMemo(() => getRowsMobile(history.list), [history.list]);

    return (
        <MainPageBlock
            title={translate('navigation.jackpot')}
        >
            <div className={classes.wrapRoot} style={{height: '100%'}}>
                <div className={classes.wrapJackpot}>
                    {
                        jackpot.map((item, key) => (
                            <div key={key} className={classes.root} style={{background: `url(${getBackground(item.jackpot)})`, backgroundSize: 'cover'}}>
                            <span
                                className={classes.labelJackpot}
                            >{getLabel(item.jackpot)}</span>
                                <span className={classes.amountJackpot}>
                                    {item.primary_amount.toFixed(2)} Р
                                </span>
                            </div>
                        ))
                    }
                </div>
                <div style={{textAlign: 'center', marginBottom: 50}}>
                    <span style={{fontSize: 20, color: '#FFF', fontFamily: 'Montserrat, sans-serif'}}>{translate('common.373')}</span>
                </div>
                <div className={classes.tableDesktop}>
                    <WinTable 
                        columns={columns}
                        rows={rows}
                        pageCount={history.page}
                        changePage={(page) =>  dispatch(setGetJackpotHistory({
                            group: profile && profile.groups_cashiers ? profile.groups_cashiers : 'all',
                            page,
                            rowsPerPage: 10
                        }))}
                        labelNotFound={translate('lottery.infoNotFound')}
                    /> 
                </div>
                <div className={classes.tableMobile}>
                    <WinTable 
                        columns={columnsMobile}
                        rows={rowsMobile}
                        pageCount={history.page}
                        changePage={(page) =>  dispatch(setGetJackpotHistory({
                            group: profile && profile.groups_cashiers ? profile.groups_cashiers : 'all',
                            page,
                            rowsPerPage: 10
                        }))}
                        labelNotFound={translate('lottery.infoNotFound')}
                    /> 
                </div>
            </div>
        </MainPageBlock>
    )
}

export default JackpotList;