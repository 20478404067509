import React from 'react';
import { translate } from '../../../../../dictionaries';
import FreekassaVisaRub from '../../components/fk/visa_rub';
import FreekassaMasterCardRUB from '../../components/fk/mastercard_rub';
import OnlineBank from '../../components/fk/onlinebank';
import Yoomoney from '../../components/fk/yoomoney';
import FreekassaFKWalletUsd from '../../components/fk/fk_wallet_usd';
import FreekassaFKWalletRub from '../../components/fk/fk_wallet_rub';
import PerfectMoneyUsd from '../../components/fk/perfect_money_usd';
import SteamPay from '../../components/fk/steam_pay';
import UstdTRC20 from '../../components/fk/usdt_trc20';
import Ethereum from '../../components/fk/ethereum';
import LiteCoinWallet from '../../components/fk/litecoint';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FortunaVisaPay from '../../components/fortuna/visa_rub';
import FortunaMasterCard from '../../components/fortuna/mastercard';
import FortunaWorldRub from '../../components/fortuna/world_rub';
import Alfakit from '../../components/alfakit/rub/visa_rub';
import AlfakitRubMC from '../../components/alfakit/rub/mastercard_rub';
import AlfakitWorldRub from '../../components/alfakit/rub/world_rub';
import AlfakitVisaEUR from '../../components/alfakit/eur/visa';
import AlfakitEURMC from '../../components/alfakit/eur/mastercard';
import AlfakitVisaKZT from '../../components/alfakit/kzt/visa';
import AlfakitKZTMC from '../../components/alfakit/kzt/mastercard';
import AlfakitVisaTRY from '../../components/alfakit/try/visa';
import AlfakitTRYMC from '../../components/alfakit/try/mastercard';
import AlfakitUZSMC from '../../components/alfakit/uzs/mastercard';
import AlfakitVisaUZS from '../../components/alfakit/uzs/visa';
import AlfakitVisaINR from '../../components/alfakit/inr/visa';
import AlfakitINRMC from '../../components/alfakit/inr/mastercard';
import PiastrixRub from '../../components/paistrix/rub';
import PiastrixUSD from '../../components/paistrix/usd';
import PiastrixEur from '../../components/paistrix/eur';
import PiastrixKZT from '../../components/paistrix/kzt';
import PiastrixTRY from '../../components/paistrix/try';
import PiastrixUZS from '../../components/paistrix/uzs';
import TapbankVisaKzt from '../../components/tapbank/kzt/visa';
import TapbankVisaUzs from '../../components/tapbank/uzs/visa';
import TapbankVisaInr from '../../components/tapbank/inr/visa';
import TapbankVisaTry from '../../components/tapbank/try/visa';
import VisaMcWorld from '../../components/visa_mc_world';
import { ReactComponent as Warning } from '../../../../../@casino/img/warning.svg';
import Sbp from '../../components/sbp';
import VisaMcKZT from '../../components/visa_mc_kzt';
import HumoUzCard from '../../components/humouzcard';

const MethodReplenishment = ({ setSelected, formik, currency, payment, user, selected }) => {
    if (!user?.is_disabled_payment_system) {
        return (
            <div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: 10,
                    background: 'rgba(4, 27, 78, 0.3)',
                    padding: 20,
                    borderRadius: 8
                }}>
                    <Warning style={{ width: 20, height: 20 }} />
                    <span style={{
                        fontFamily: 'Montserrat, sans-serif',
                        color: '#FFFFFF'
                    }}>При оплате картой, попробуйте все варианты по порядку</span>
                </div>
                <div>
                    <div style={{ marginTop: 25, display: 'flex', columnGap: 12, flexWrap: 'wrap', rowGap: 12 }}>
                        {
                            payment.map((item, key) => {
                                // RUB
                                if (currency === 'RUB' && item.status) {
                                    if (
                                        item.label === 'WinRUB' ||
                                        item.label === 'TapbankRUB' ||
                                        item.label === 'AlfakitRUB' ||
                                        item.label === 'FreekassaVisaRub' ||
                                        item.label === 'FreekassaMastercardRub' ||
                                        item.label === 'ExpayCard'
                                    ) {
                                        return (
                                            <VisaMcWorld {...item} setSelected={setSelected} formik={formik} />
                                        )
                                    }
                                    if (item.label === 'ExpaySBP') {
                                        return (
                                            <Sbp {...item} setSelected={setSelected} formik={formik}/>
                                        )
                                    }

                                    if (item.label === 'OnlineBank') {
                                        return <OnlineBank {...item} key={key} setSelected={setSelected} formik={formik} />
                                    }
                                    if (item.label === 'Yoomoney') {
                                        return <Yoomoney {...item} key={key} setSelected={setSelected} formik={formik} />
                                    }
                                    if (item.label === 'FreekassaFKWalletRub') {
                                        return <FreekassaFKWalletRub {...item} key={key} setSelected={setSelected} formik={formik} />
                                    }
                                    if (item.label === 'SteamPay') {
                                        return <SteamPay {...item} key={key} setSelected={setSelected} formik={formik} />
                                    }
                                    if (item.label === 'PiastrixRUB') {
                                        return (
                                            <PiastrixRub {...item} key={key} setSelected={setSelected} formik={formik} />
                                        )
                                    }
                                }
                                
                                if (item.label === 'FreekassaFKWalletUsd' && currency === 'USD' && item.status) {
                                    return <FreekassaFKWalletUsd key={key} setSelected={setSelected} formik={formik} />
                                }
                                if (item.label === 'PerfectMoneyUsd' && currency === 'USD' && item.status) {
                                    return <PerfectMoneyUsd key={key} setSelected={setSelected} formik={formik} />
                                }
                                if (item.label === 'PiastrixUSD' && currency === 'USD' && item.status) {
                                    return (
                                        <PiastrixUSD key={key} setSelected={setSelected} formik={formik} />
                                    )
                                }

                                // EUR

                                if (item.label === 'AlfakitEUR' && currency === 'EUR' && item.status) {
                                    return (
                                        <>
                                            <AlfakitVisaEUR key={key} setSelected={setSelected} formik={formik} />
                                            <AlfakitEURMC key={key} setSelected={setSelected} formik={formik} />
                                        </>
                                    )
                                }
                                if (item.label === 'PiastrixEUR' && currency === 'EUR' && item.status) {
                                    return (
                                        <>
                                            <PiastrixEur key={key} setSelected={setSelected} formik={formik} />
                                        </>
                                    )
                                }


                                // KZT
                                if (currency === 'KZT' && item.status) {
                                    if (
                                        item.label === 'AlfakitKZT' ||
                                        item.label === 'TapbankKZT'
                                    ) {
                                        return (
                                            <VisaMcKZT {...item} key={key} setSelected={setSelected} formik={formik} />
                                        )
                                    }

                                    if (item.label === 'PiastrixKZT') {
                                        return (
                                            <>
                                                <PiastrixKZT key={key} setSelected={setSelected} formik={formik} />
                                            </>
                                        )
                                    }
                                }

                                if (currency === 'UZS' && item.status) {
                                    if (item.label === 'TapbankUZS' || item.label === 'AlfakitUZS' || item.label === 'ExpayUZS') {
                                        return (
                                            <HumoUzCard {...item} key={key} setSelected={setSelected} formik={formik}  />
                                        )
                                    }
    
                                    if (item.label === 'PiastrixUZS') {
                                        return (
                                            <>
                                                <PiastrixUZS key={key} setSelected={setSelected} formik={formik} />
                                            </>
                                        )
                                    }
                                }
                               



                               
                               

                                // if (item.label === 'AlfakitUZS' && currency === 'UZS' && item.status) {
                                //     return (
                                //         <>
                                //             <AlfakitVisaUZS key={key} setSelected={setSelected} formik={formik} />
                                //             <AlfakitUZSMC key={key} setSelected={setSelected} formik={formik} />
                                //         </>
                                //     )
                                // }

                                // TRY
                                if (item.label === 'PiastrixTRY' && currency === 'TRY' && item.status) {
                                    return (
                                        <>
                                            <PiastrixTRY key={key} setSelected={setSelected} formik={formik} />
                                        </>
                                    )
                                }
                               
                                if (item.label === 'TapbankTRY' && currency === 'TRY' && item.status) {
                                    return (
                                        <TapbankVisaTry setSelected={setSelected} formik={formik} />
                                    )
                                }

                                if (item.label === 'AlfakitTRY' && currency === 'TRY' && item.status) {
                                    return (
                                        <>
                                            <AlfakitVisaTRY key={key} setSelected={setSelected} formik={formik} />
                                            <AlfakitTRYMC key={key} setSelected={setSelected} formik={formik} />
                                        </>
                                    )
                                }
                                // INR
                                
                                if (item.label === 'TapbankINR' && currency === 'INR' && item.status) {
                                    return (
                                        <TapbankVisaInr setSelected={setSelected} formik={formik} />
                                    )
                                }

                                if (item.label === 'AlfakitINR' && currency === 'INR' && item.status) {
                                    return (
                                        <>
                                            <AlfakitVisaINR key={key} setSelected={setSelected} formik={formik} />
                                            <AlfakitINRMC key={key} setSelected={setSelected} formik={formik} />
                                        </>
                                    )
                                }

                            })
                        }
                        <UstdTRC20 setSelected={setSelected} formik={formik} currency={currency} />
                        <Ethereum setSelected={setSelected} formik={formik} currency={currency} />
                        <LiteCoinWallet setSelected={setSelected} formik={formik} currency={currency} />
                    </div>
                </div>
                <div style={{ marginTop: 25, display: 'flex', columnGap: 12, flexWrap: 'wrap', rowGap: 12 }}>

                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default MethodReplenishment;