import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { setGetSettingsBanners } from '../../../../../../store/settings/thunk/setGetSettingsBanners';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useRouter } from '../../../../../../@casino/hook/useRouter';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 1140, 
        margin: '0 auto', 
        marginTop: 80, 
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            width: 'auto'
        }
    }
}))

const BannerList = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const router = useRouter();

    React.useEffect(() => {
        dispatch(setGetSettingsBanners({
            type: "DESKTOP",
            language: localStorage.getItem('language') || 'ru'
        }));
    }, [])

    const list = useSelector((state) => state.settings.banners);
  
    return (
        <div style={{ minHeight: 430 }}>
            {
                list?.length && (
                    <Carousel
                        autoPlay={true}
                        infiniteLoop
                        showStatus={false}
                        showThumbs={false}
                        showArrows={false}
                        emulateTouch
                        interval={10000}
                    >
                        {
                            list.map(({link_redirect, url}, key) => (
                                <div key={key} className={classes.root} style={{ position: 'relative' }} onClick={() => link_redirect && router.history.push(link_redirect)}>
                                    <div style={{ marginLeft: 2, marginTop: -40 }}>
                                        <img src={url} alt="banner" style={{ width: 1140, height: 390 }} />
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
                )
            }
        </div>
    )
}

export default BannerList;