import React from 'react';
import BannerList from '../../components/banners/page/list';
import Bonuses from '../components/bonuses';
import ListGames from '../components/games';
import ListLastWin from '../components/lastwin';
import Navigation from '../components/navigation/list';
import Logo from '../../../../@casino/img/logo.svg';
import BgBody from '../../../../@casino/img/bgBody.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { setGetListBonuses } from '../../bonuses/store/thunk/setGetListBonuses';
import { setGetJackpotList } from '../store/thunk/setGetJackpotList';
import Header from '../../../template/header/page/list';
import { useRouter } from '../../../../@casino/hook/useRouter';
import NavigationMobile from '../components/navigationMobile';
import makeStyles from '@material-ui/core/styles/makeStyles';
import BgHeader from '../../../../@casino/img/headerbg.png';
import MobileHome from './mobile_home/page';
import { translate } from '../../../../dictionaries';
import { setGetFreespin } from '../../../template/FreespinModal/store/thunk/setGetFreespin';
import Freespin from '../../../freespin';

const useStyles = makeStyles((theme) => ({
    header: {
        minHeight: '900px',
        position: 'relative',

        background: `url(${BgHeader}) top center no-repeat`,
        [theme.breakpoints.down('sm')]: {
            backgroundPositionY: '-100px'
        }
    },
    listGame: {
        width: 1140,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: 'auto'
        }
    },
    bonuses: {
        paddingTop: 106, paddingBottom: 116
    },
    listlastWin: {
        backgroundPositionY: 'bottom',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down('sm')]: {
            margin: '0 10px'
        }
    },
    wrapHeader: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    wrapHeader2: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: 34,
            width: '100%',
            maxWidth: 1140,
            margin: '0 auto',
        },
    },
    headers: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            background: '#142A8F',
            height: 64,
            position: 'absolute',
            top: 0,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around'
        }
    },
    wrapDots: {
        height: 100,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    navigationDesktop: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    navigationMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            marginTop: 140
        }
    },
    wrapRootNavigation: {
        position: 'absolute',
        zIndex: 100,
        right: -20
    },
    rootNavigation: {
        display: 'flex',
        flexDirection: 'column',
        width: 320,
        padding: '22px 17px 12px 17px',
        background: '#002062',
        borderRadius: 3
    },
    wrapItemNavigation: {
        padding: '10px 0 10px 30px',
        background: '#142A8F',
        borderRadius: 3,
        marginBottom: 10,
        cursor: 'pointer',
        transition: 'background .3s ease',
        '&:hover': {
            background: '#7B61FF',
            transition: 'background .3s ease',
        }
    },
    itemNavigation: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#FFFFFF',
    },
    activeItemNavigation: {
        position: 'relative',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#FFFFFF',
        '&::after': {
            position: 'absolute',
            top: -5,
            left: -20,
            content: '""',
            display: 'block',
            height: 30,
            width: 3,
            background: '#FF3156'
        }
    },
    activeWrapItemNavigation: {
        padding: '10px 0 10px 30px',
        background: 'linear-gradient(270deg, rgba(20, 42, 143, 0.5) 0%, rgba(0, 74, 236, 0.5) 100%), #142A8F',
        borderRadius: 3,
        marginBottom: 10,
    },
    bannerTitle: {
        color: 'gold',
        fontSize: 16,
        textTransform: 'uppercase',
        // fontWeight: 700,
        position: 'relative',
        left: 10,
        '&::after': {
            content: "''",
            position: 'absolute',
            display: 'block',
            top: 0,
            left: -10,
            width: 2,
            height: 16,
            background: '#FF005C'
        }
    },
    present: {
        position: 'fixed',
        bottom: 30,
        right: 30,
        zIndex: 100,
        animation: '$presentEffect .9s infinite linear',
        '-webkit-animation': '$presentEffect .9s infinite linear',
        cursor: 'pointer'
    },
    "@keyframes presentEffect": {
        "0%": {
            bottom: 30,
            transform: 'scale(1)'
        },

        "50%": {
            bottom: 50,
            transform: 'scale(1.3)'
        },

        "100%": {
            bottom: 30,
            transform: 'scale(1)'
        }
    },
}))

const Home = ({ auth }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    document.title = translate('title.main');
    const classes = useStyles();
    const dispatch = useDispatch();
    const router = useRouter();
    const profile = useSelector(state => state.profile.view);
    const token = localStorage.getItem('token');
    const { pathname } = router;
    const [nav, setNav] = React.useState(false);
    const [provider, setProvider] = React.useState("");
    const [type, setType] = React.useState('slots');

    React.useEffect(() => {
        dispatch(setGetListBonuses());
        if (profile && profile.groups_cashiers) {
            dispatch(setGetJackpotList({
                group: profile.groups_cashiers
            }))
        } else if (!!!token) {
            dispatch(setGetJackpotList({
                group: 'all'
            }))
        }
    }, [profile, token])

    const bonuses = useSelector(state => state.bonuses.listBonuses)
    const jackpot = useSelector(state => state.games.list_jackpot);
    const loading = useSelector(state => state.loading.loading.loading);

    React.useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(setGetFreespin())
        }
    }, [])

    const [dots, setDots] = React.useState(1);
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        return (
            <MobileHome />
        )
    } else {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                
                <div
                    className={classes.header}
                >
                    <div className={classes.wrapHeader}
                    >
                        <Header auth={auth} />
                    </div>
                    <div className={classes.wrapHeader2}
                    >
                        <Freespin profile={profile}/>
                    </div>
                    <div className={classes.headers}
                    >
                        <img src={Logo} alt="logo" onClick={() => router.history.push('/')} style={{ cursor: 'pointer', height: 50, marginTop: 8 }} />
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <div
                                style={{ display: 'flex', flexDirection: 'column', padding: '12px 9px', background: '#FF005C', borderRadius: '3px', marginRight: 40 }}
                                onClick={() => setNav(!nav)}>
                                <span style={{ width: 22, height: 3, background: '#fff', marginBottom: 4 }}></span>
                                <span style={{ width: 22, height: 3, background: '#fff', marginBottom: 4 }}></span>
                                <span style={{ width: 22, height: 3, background: '#fff' }}></span>
                            </div>
                            <div className={classes.wrapRootNavigation} style={{ top: nav ? 80 : -1180, transition: 'top .5s ease' }}>
                                <div className={classes.rootNavigation}>
                                    <div
                                        className={pathname === '/' ? classes.activeWrapItemNavigation : classes.wrapItemNavigation}
                                        onClick={() => {
                                            router.history.push('/');
                                            setNav(false)
                                        }}
                                    >
                                        <span
                                            className={pathname === '/' ? classes.activeItemNavigation : classes.itemNavigation}
                                        >{translate('navigation.main')}</span>
                                    </div>
                                    <div
                                        onClick={() => {
                                            router.history.push('/bonuses')
                                            setNav(false)
                                        }}
                                        className={pathname === '/bonuses' ? classes.activeWrapItemNavigation : classes.wrapItemNavigation}>
                                        <span
                                            className={pathname === '/bonuses' ? classes.activeItemNavigation : classes.itemNavigation}
                                        >{translate('navigation.bonuses')}</span>
                                    </div>
                                    <div
                                        className={pathname === '/jackpot' ? classes.activeWrapItemNavigation : classes.wrapItemNavigation}
                                        onClick={() => {
                                            router.history.push('/jackpot')
                                            setNav(false)
                                        }}
                                    >
                                        <span
                                            className={pathname === '/jackpot' ? classes.activeItemNavigation : classes.itemNavigation}
                                        >{translate('navigation.jackpot')}</span>
                                    </div>
                                    <div
                                        className={pathname === '/tournament' ? classes.activeWrapItemNavigation : classes.wrapItemNavigation}
                                        onClick={() => {
                                            router.history.push('/tournament');
                                            setNav(false);
                                        }}
                                    >
                                        <span
                                            className={pathname === '/tournament' ? classes.activeItemNavigation : classes.itemNavigation}
                                        >{translate('navigation.tournament')}</span>
                                    </div>
                                    {
                                        window.localStorage.getItem('token') &&
                                        <div
                                            className={pathname.indexOf('/profile') > -1 ? classes.activeWrapItemNavigation : classes.wrapItemNavigation}
                                            onClick={() => {
                                                router.history.push('/profile/account');
                                                setNav(false);
                                            }}
                                        >
                                            <span
                                                className={pathname.indexOf('/profile') > -1 ? classes.activeItemNavigation : classes.itemNavigation}
                                            >{translate('navigation.profile')}</span>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>

                    </div>
                    <BannerList dots={dots} setDots={setDots} allDots={4} />
                    <div className={classes.wrapDots} style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
                        {/* <img src={Referral} style={{ borderRadius: 12, cursor: 'pointer' }} onClick={() => {
                            if (localStorage.getItem('token')) {
                                router.history.push('/profile/referral')
                            }
                        }} /> */}
                    </div>
                    <div className={classes.navigationDesktop}>
                        <Navigation jackpot={jackpot} setProvider={setProvider} setType={setType} />
                    </div>
                    <div className={classes.navigationMobile}>
                        <NavigationMobile jackpot={jackpot} setProvider={setProvider} setType={setType} />
                    </div>

                </div>
                <div
                    className={classes.listGame}
                    style={{ minHeight: 500 }}
                >
                    <ListGames provider={provider} type={type} loading={loading} />
                </div>
                <div
                    className={classes.bonuses}
                    style={{ background: `url(${BgBody})` }}
                >
                    <Bonuses bonuses={bonuses} main />
                </div>

                <div
                    className={classes.listlastWin}
                >
                    <ListLastWin />
                </div>
            </div>
        )
    }
}

export default Home