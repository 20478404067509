import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../../dictionaries';
import FS from '../img/fs3.png';
import {useRouter} from '../../../../@casino/hook/useRouter';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 109,
    },
    wrapTitle: {
        marginBottom: 28
    },
    title: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '37px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF',
    },
    bonus: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#001137',
        borderRadius: '3px',
        width: 300,
        height: 120,
        marginRight: 20
    },
    activeBonus: {
        border: '1px solid #FF005C',
        boxSizing: 'border-box',
        boxShadow: '0px 3px 20px #FF005C',
    },
    textBonus: {
        width: '51%',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '29px',
        textAlign: 'center',
        paddingRight: 20
    },
    colorTextBonus: {
        color: '#FFFFFF',
    },
    colorTextActiveBonus: {
        color: '#FF005C',
    },
    iconBonus: {
        width: 117,
    },
    wrapBonusDesktop: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 31,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 20,
            alignItems: 'center',
            marginBottom: 40
        }
    },
    field2: {
        width: 300,
        border: '1px solid #8E9EC2',
        boxSizing: 'border-box',
        borderRadius: '3px',
        padding: '15px 19px',
        background: 'inherit',
        color: '#fff',
        outline: 'none',
        fontFamily: 'Montserrat, sans-serif',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        }
    },
    labelHelper: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        width: 171,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
}))

const BonusesFreespin = ({ profile, onSubmit, btn, date, max_date, loading}) => {
    document.title = translate('title.bonuses')
    const classes = useStyles();

    const [finishTime] = React.useState(Number(date) + max_date);

    const [[diffDays, diffH, diffM, diffS], setDiff] = React.useState([0, 0, 0, 0]);
    const [tick, setTick] = React.useState(false);

    React.useEffect(() => {
        const diff = (finishTime - new Date()) / 1000;
        if (diff < 0) return
        setDiff([
            Math.floor(diff / 86400),
            Math.floor((diff / 3600) % 24),
            Math.floor((diff / 60) % 60),
            Math.floor(diff % 60)
        ])
    }, [tick, finishTime])

    React.useEffect(() => {
        const diff = (finishTime - new Date()) / 1000;
        if (diff > 0) {
            const timerID = setInterval(() => setTick(!tick), 1000);
            return () => clearInterval(timerID);
        }
    }, [tick]);


    return (
        <div>

            <div>
                <div style={{ boxShadow: 'rgba(0, 11, 36, 0.5) 0px 10px 20px', maxWidth: 380, width: '100%', borderRadius: 30, height: 405 }}>
                    <div style={{
                        maxWidth: 380,
                        width: '100%',
                        height: 405,
                        background: '#012E90',
                        boxShadow: ' inset 0px 0px 100px 14px rgba(83, 28, 201, 1)',
                        borderRadius: 30,
                        position: 'relative'
                    }}>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 30, marginBottom: 20 }}>
                            <img src={FS} alt="" style={{ width: '300px' }} />
                        </div>
                        <div style={{ padding: '0 30px', display: 'flex', justifyContent: 'space-between' }}>
                            <span style={{ fontFamily: 'Montserrat, sans-serif', color: '#fff', fontWeight: 700, fontSize: 18, textTransform: 'uppercase', textShadow: '2px 2px 15px rgba(0,0,0,0.57)' }}>{translate('common.fs')}</span>
                            <span style={{ fontFamily: 'Montserrat, sans-serif', color: '#fff', fontWeight: 700, fontSize: 18, textShadow: '2px 2px 15px rgba(0,0,0,0.57)' }}>{profile?.fs?.quantity} {translate('common.count')}</span>
                        </div>
                        <div style={{ padding: '0 30px', display: 'flex', justifyContent: 'space-between', marginTop: 8 }}>
                            <span style={{ fontFamily: 'Montserrat, sans-serif', color: '#fff', fontWeight: 700, fontSize: 14, textShadow: '2px 2px 15px rgba(0,0,0,0.57)', textTransform: 'capitalize' }}>{profile?.fs?.game_name}</span>
                        </div>
                        <div style={{ margin: '0 30px', display: 'flex', flexDirection: 'column', marginTop: 20, borderRadius: 4, background: 'rgba(255,255,255,0.1)' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 10px', color: '#fff', fontSize: 14 }}>
                                <span>{translate('common.replay')}</span>
                                <span>x{profile?.fs?.wager_amount}</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 10px', color: '#fff', fontSize: 14 }}>
                                <span>{translate('common.deadline')}</span>
                                <span>{diffDays} {translate('common.days')} {diffH < 10 ? '0' + diffH : diffH}:{diffM < 10 ? '0' + diffM : diffM}:{diffS < 10 ? '0' + diffS : diffS}</span>
                            </div>
                        </div>
                        {
                            loading && (
                                <div style={{margin: '0 30px'}}>
                                    <span  style={{ fontFamily: 'Montserrat, sans-serif', color: '#fff', fontWeight: 700, fontSize: 14, textShadow: '2px 2px 15px rgba(0,0,0,0.57)' }}>{translate('common.fs_wait_activate')}</span>
                                </div>
                            )
                        }
                        {
                            !loading && (
                                <div style={{ display: 'flex', justifyContent: 'flex-start', position: 'absolute', bottom: 20, left: 30 }}>
                                    <span
                                        onClick={onSubmit}
                                        style={{
                                            background: '#ff005d',
                                            padding: '5px 10px',
                                            borderRadius: 6,
                                            color: '#ffffff',
                                            cursor: 'pointer'
                                        }}
                                    >{btn}</span>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BonusesFreespin;