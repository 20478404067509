import React from 'react';
import { useSelector } from 'react-redux';
import {useRouter} from '../../../../../../../@casino/hook/useRouter';
import makeStyles from '@material-ui/core/styles/makeStyles'
import Dialog from '@material-ui/core/Dialog';
import {translate} from '../../../../../../../dictionaries';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '29px 10px 0px 10px',
        // background: 'rgba(2, 0, 4, 0.55)',
        marginBottom: 15,
    },
    item_nav: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#8e9ec2',
        marginRight: 28,
        cursor: 'pointer',
        transition: 'color .2s ease',
        '&:hover': {
            color: '#FF005C',
            transition: 'color .2s ease',
        }
    },
    item_active: {
        color: '#FF005C'
    },
    btn: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        border: '1px solid #FF005C',
        borderRadius: '50px',
        padding: '10px 40px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        color: '#FF005C',
        '&:hover': {
            background: '#ED074C',
            color: '#fff',
            transition: 'background, color .3s ease',
            
        }
    },
    gamegame: {
        position: 'absolute', 
        top: 0, 
        width: '100%', 
        height: 141, 
        background: 'rgba(0,0,0,.5)', 
        zIndex: 100, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        opacity: 0,
        transition: 'opacity .3s ease',
        '&:hover': {
            opacity: 1,
            transition: 'opacity .3s ease',
        }
    },
    search: {
        border: 'none',
        borderRadius: 3,
        paddingTop: 2,
        height: 40,
        width: '100%',
        color: '#D2D8E3',
        fontSize: 16,
        fontFamily: 'Montserrat, sans-serif',
        background: 'rgba(2, 0, 4, 0.3)',
        boxShadow: 'inset 0px 4px 4px rgba(2, 0, 4, 0.1)',
        paddingLeft: 20,
        outline: 'none',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        }
    },
    wrapSearch: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    wrapGames: {
        paddingBottom: 60, 
        display: 'flex', 
        justifyContent: 'flex-start', 
        flexWrap: 'wrap', 
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 10
        }
    },
    wrapGameItem: {
        width: 210, 
        height: 141, 
        borderRadius: 3, 
        position: 'relative', 
        transition: 'all .3s ease',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minHeight: 141
        }
    },
    wrapImageGame: {
        width: 210, 
        height: 141, 
        borderRadius: 3,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minHeight: 141
        }
    }
}))
const LiveGame = () => {
    const games = useSelector((state) => state.mobile_games.live);
    const profile = useSelector(state => state.profile.view);
    const router = useRouter();
    const [showModal, setShowModal] = React.useState(false);
    const classes = useStyles();
    return (
        <div style={{
            background: 'rgba(2, 0, 36, 0.8)',
            display: 'flex', 
            flexDirection: 'column',
            padding: 12,
            position: 'relative',
            minHeight: 120
        }}>
            {/* <div style={{position: 'absolute', zIndex: 12, bottom: 16, right: 0, height: 110, width: '100%', background: 'linear-gradient(270deg, #000 0, rgba(10,14,21,0) 100%'}}></div> */}
            <div  style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0'}}>
               <span style={{
                   fontFamily: 'Montserrat, sans-serif',
                   fontSize: 20,
                   color: '#fff'
               }}>
                   Live
               </span>
               <span 
                onClick={() => router.history.push('/mobile/live')}
               style={{
                   fontFamily: 'Montserrat, sans-serif',
                   fontSize: 14,
                   color: 'rgb(255, 0, 92)'
               }}>{translate('common.add')}</span>
           </div>
           <div style={{display: 'flex', minHeight: 110,  overflow: 'scroll'}}>
            {
                games.list.map((item, key) => (
                    <div
                        key={key}
                        onClick={() => {
                            if ((item.provider === 'Betgames' || item.provider === 'Ezugi') && (profile && profile.air_balance)) {
                                setShowModal(true)
                            } else {
                                window.location.replace(window.location.origin + '/games/' + item.uuid)
                            }
                        }}
                        style={{marginRight: 8, borderRadius: 4, position: 'relative'}}
                    >
                        <img 
                            src={item.image}
                            style={{width: 150, height: 110, borderRadius: 4, 
                                
                                // background: 'linear-gradient(to top,#000 0,rgba(10,14,21,0) 100%)'
                            }}
                            alt=""/>
                        <div style={{
                            background: 'linear-gradient(0deg, #000 0, rgba(10,14,21,0) 30%',
                            width: 150,
                            height: 110,
                            position: 'absolute',
                            zIndex: 10,
                            top: 0,
                            left: 0
                        }}>

                        </div>
                        <span style={{position: 'absolute', bottom: 10, left: 5, zIndex: 11, color: '#fff', fontSize: 12, fontFamily: 'Montserrat, sans-serif'}}>{item.name}</span>
                    </div>
                ))
            }
           </div>
           <Dialog
                open={showModal}
                onClose={() => setShowModal(false)}
            >
                <div className={classes.root}>
                    <div style={{display: 'flex', justifyContent: 'center', padding: '0 20px 20px', flexDirection: 'column', alignItems: 'center'}}>
                        <h3 style={{
                            margin: 0, 
                            padding: 0,
                            fontFamily: 'Montserrat, sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '16px',
                        
                            display: 'flex',
                            alignItems: 'center'
                            }}>{translate('notification.noaccessLive')}</h3>
                        <span style={{
                                marginTop: 12,
                                borderRadius: '50px',
                                padding: '5px 60px',
                                fontFamily: 'Montserrat, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                background: '#FF005C',
                                color: '#FFFFFF',
                                cursor: 'pointer'
                            }}
                                onClick={() => setShowModal(false)}
                            >{translate('common.ok')}</span>
                    </div>
        
                </div>
            </Dialog>
        </div>
    )
}

export default LiveGame;