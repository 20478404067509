import {postConfirmationStepRegistration} from '../api/postConfirmationStepRegistration';

export const setPostConfirmationStepRegistration = (params, setShowModalConfirm, setError, setIsLoading) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postConfirmationStepRegistration(params);
            setShowModalConfirm(data.data.messageUuid);
            if (setIsLoading) {
                setIsLoading(false);
            }
        } catch(error) {
            setError(true)
        }
    }
}