import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGameInit } from '../../store/thunk/setGameInit';
import { setGetBonusesNotification } from '../../store/thunk/setGetBonusesNotification';
import { setGameDemo } from '../../store/thunk/setGetGameDemo';
import { setGetJackpotList } from '../../store/thunk/setGetJackpotList';
import GameId from '../gameId';
import Test from './block/test';
import {useRouter} from '../../../../../@casino/hook/useRouter';
import Loader from '../../../../loader';

const GameItem = ({profile}) => {
    const dispatch = useDispatch()
    const router = useRouter();
    const uuid = router.match.params.game_uuid;
 
    React.useEffect(() => {
        if (!localStorage.getItem('token')) {
            dispatch(setGameDemo({
                game_uuid: uuid,
            }))
            dispatch(setGetJackpotList({
                group: 'all'
            }))
        } else if (profile) {
            dispatch(setGameInit({
                game_uuid: uuid,
            }))
            dispatch(setGetJackpotList({
                group: profile.groups_cashiers
            }))
        }
    }, [profile])
   
    const url = useSelector((state) => state.games.gameId);
    if (!url) {
        return <><Loader /></>
    }

    return (
        <>  
            {
                url && <GameId profile={profile} url={url} />
            }
        </>
    )
}

export default GameItem;